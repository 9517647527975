import Vue from 'vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


import GlacierMap from "./vue/GlacierMap.vue";


new Vue({
    el: '#vue-app',
    name: 'GlacierMap instance',
    components: {
        GlacierMap
    },
    data: {},
    methods: {},
    computed: {},
    watch: {},
    mounted: function () {}
});
