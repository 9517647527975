/**
GlacierMap.vue
*/
<!--suppress JSPotentiallyInvalidUsageOfThis -->


<template>
    <div class="glacier-map-interface">
        <div class="frame frame1" v-if="frame === 1">
            <div class="content-window content-window-background">
                <intro-page v-bind:loading-state="loadingState" v-on:user-confirmed="frame = 2.5"/>
            </div>
        </div>

<!--        <div class="frame frame2" v-if="frame === 2"></div>-->

        <div class="frame frame2b" v-if="frame === 2.5">
            <div class="content-window content-window-background">
                <div>
                    <div class="split-content">
                        <div>
                            <p>
                                Here is a map of the world with Peru highlighted.
                                The Peruvian Andean mountains (labelled as ANDES MNTS on the smaller map of Peru) reach higher than 6,000 m above sea level, and are home to many of the world’s tropical glaciers.
                                Glaciers are an important source of water for the environment and for people.
                            </p>
                        </div>

                        <div>
                            <button class="btn btn-primary btn-lg" v-on:click.prevent="frame = 3">Click/tap here to move on</button>
                        </div>
                    </div>

                    <img src="Peru_map.jpg" alt="Map of Peru"/>
                </div>
            </div>
        </div>

        <div class="frame frame3" v-if="frame === 3">
            <div id="map1"></div>

            <div class="instructions-modal" v-if="showDrawingInstructions" v-on:click="showDrawingInstructions = false">
                <div>
                    <div v-on:click="showDrawingInstructions = false">X</div>
                    <h4>How to map a glacier</h4>
                    <p>
                        <img src="draw-icon.png" alt="draw icon">
                        Try to draw a line around what you consider to be the edge of the glacier by selecting the shape icon (the text “draw a polygon” will appear when you hover the mouse over this) and clicking at multiple points around the edge of the glacier. Double click to “connect the dots” and complete your shape.
                    </p>
                    <p>
                        If you are not happy with your mapping you can press the “undo” button to retrace your steps or start again. This will allow you to practise if you wish. You will also be given the option to delete the shape and start again after double clicking. To move the satellite image into a better position on the screen click on the four-headed arrow button then drag the page.
                    </p>
                    <p>
                        Do not worry if you are unsure about your mapping, sometimes snow and debris can make it difficult to interpret where the glacier edge starts and ends, so just do your best!
                    </p>
                </div>
            </div>

            <div v-if="outline1984" class="confirm-sketch">
              If you're not happy with your shape, you can edit it by clicking/tapping it once to allow moving to a new location,
              and again to change the individual points that make up the boundary.
              <p>
                  <button class="btn btn-warning btn-sm" v-on:click.prevent="deleteLiveBoundary(1984)">Delete shape and start again</button>
                  <button class="btn btn-primary btn-sm" v-on:click.prevent="submitBoundaryAndMoveOn(1984)">Move on to next map</button>
              </p>
            </div>

            <div class="map-overlay-content">
                <show-hide-text-tool>
                    <div slot="content">
                        <p>
                            We would now like you to have a go at mapping a Peruvian glacier.
                        </p>
                        <p>
                            We are mapping glaciers in the Cordillera Blanca mountain range in the Peruvian Andes,
                            which contains the largest area of tropical glaciers in the world! The image you can
                            see was taken back in 1984 by the Landsat satellite and the green cross marks the approximate
                            center of the glacier.
                        </p>

                        <button style="margin: 0 0 10px 0 !important;" class="btn btn-primary btn-sm" v-on:click="showDrawingInstructions = true">
                            Click here to learn how to map the glacier you've been assigned.
                        </button>

                    </div>
                </show-hide-text-tool>
            </div>
        </div>

        <div class="frame frame4" v-if="frame === 4">
            <div id="map2"></div>

            <div v-if="outline2018" class="confirm-sketch">
              If you're not happy with your shape, you can edit it by clicking/tapping it once to allow moving to a new location,
              and again to change the individual points that make up the boundary.
              <p>
                  <button class="btn btn-warning btn-sm" v-on:click.prevent="deleteLiveBoundary(2018)">Delete shape and start again</button>
                  <button class="btn btn-primary btn-sm" v-on:click.prevent="submitBoundaryAndMoveOn(2018)">Move on to next map</button>
              </p>
            </div>

            <div class="map-overlay-content">
                <show-hide-text-tool>
                    <div slot="content">
                        <p>Great work! For this second part, we now ask for you to try mapping the same glacier again, but from this image taken more recently, in 2018.</p>
                        <p>
                            <img src="draw-icon.png" alt="draw icon">
                            Like before, please just do your best at drawing a line around what you consider to be the edge of the glacier.
                        </p>
                    </div>
                </show-hide-text-tool>
            </div>
        </div>

        <div class="frame frame5" v-if="frame === 5">
            <div id="map3"></div>
            <div class="map-overlay-content" style="top: 15px;">
                <p>Thank you for contributing those two glacier outlines to our project!</p>
                <p>We can show you here the two outlines that you mapped. The blue shape is the glacier you mapped from 1984, and the red shape is the glacier you mapped from 2018. Any difference you see between the two outlines indicates change in the glacier extent over those 34 years.</p>
                <p>Please continue on to finish GlacierMap to learn more about the impacts of glacier retreat in Peru.</p>
                <p style="margin: 0; text-align: center">
                    <button class="btn btn-primary" v-on:click.prevent="hideMap3andMoveOn">Click/tap here<br>to move on</button>
                </p>
            </div>
        </div>

        <div class="frame frame6" v-if="frame === 6">
            <div class="content-window content-window-background">
                <div>
                    <div class="split-content">
                        <div>
                            <p>
                                For many of the glaciers in the Peruvian Andes, there will be a difference in the glacier outlines over the last 34 years. This represents glacial retreat, where glaciers become smaller and smaller in area, mainly due to a warming climate.
                            </p>

                            <p>
                                Please use this diagram to find out more information about glacial retreat in Peru and the impacts of the loss of glaciers for people living there and the environment.
                            </p>

                        </div>

                        <div>
                            <button class="btn btn-primary btn-lg" v-on:click.prevent="frame = 8">Click/tap here to move on</button>
                        </div>
                    </div>

                    <img src="GlacierMap%20impacts%20infographic%20final.jpg" alt="An image describing glacier retreat">
                </div>
            </div>
        </div>

<!--        <div class="frame frame7" v-if="frame === 7"></div>-->

        <div class="frame frame8" v-if="frame === 8">
            <div class="content-window content-window-background">
                <div>
                    <div v-if="glacierList.length > 0" v-cloak>
                        <h2>Would you like to do the glacier mapping exercise again for a different glacier before you finish?</h2>
                        <p>
                            If so, please feel free to have another go here! Any mapping you do will contribute to the citizen science glacier inventory for the Cordillera Blanca mountain range in Peru.
                        </p>
                        <p>If not, please carry on to our final page!</p>
                    </div>

                    <div v-if="glacierList.length === 0">
                        <h2>You've mapped all of the glaciers! Thank you for your contribution.</h2>
                    </div>

                    <div v-if="glacierList.length > 0" style="display: inline-block">
                        <button class="btn btn-primary btn-lg"
                                v-on:click.prevent="digitiseAnotherGlacier" v-cloak>
                            Digitise another glacier
                        </button>
                    </div>

                    <div style="display: inline-block">
                        <button class="btn btn-primary btn-lg" v-on:click.prevent="frame = 9">Finish</button>
                    </div>

                    <div v-if="glacierList.length === 0" v-cloak>Digitised all the glaciers message</div>
                    <br>

                </div>
            </div>
        </div>

        <div class="frame frame9" v-if="frame === 9">
            <div class="final-image" style="background: url('laguna_69_glacier_peru.jpg') no-repeat center center fixed;"></div>
            <div class="content-window">
                <div>
                    <h2>Thank you for contributing to GlacierMap!</h2>
                    <p>A huge <span style="font-weight: bold">THANK YOU</span> from the GlacierMap team for your interest in our research and for taking the time to contribute to our citizen science glacier inventory!</p>
                    <p>Please feel free to share GlacierMap with your friends, colleagues, and students.</p>
                    <div>
                        To learn more about our research exploring glacier retreat and its impact on water security in the Peruvian Andes, please visit our project website and Twitter page via the links below. You can also find our contact details on the SIGMA: Peru website if you have any questions about GlacierMap or would like to give us feedback.
                        <ul>
                            <li>SIGMA: Peru website: <a href="https://sigmaperu.wordpress.com/" target="_blank">https://sigmaperu.wordpress.com/</a></li>
                            <li>Twitter: <a href="https://twitter.com/sigmaperu" target="_blank">@sigmaperu</a></li>
                        </ul>
                    </div>

                    <p>
                        If you would like to learn more about how GlacierMap can be used to support geographical learning, please take a look at our paper in the Geographical Association’s Geography journal:
                        <a href="https://doi.org/10.1080/00167487.2021.1970932" target="_blank">https://doi.org/10.1080/00167487.2021.1970932</a>.
                    </p>

                </div>
            </div>
        </div>

    </div>
</template>

// 1-old: "https://survey123.arcgis.com/share/690c8420210c49e29f727ba4f97d0f80?field:userid="
// 2-old: "https://survey123.arcgis.com/share/b1791f7fb5244e2ca714e213b8f272b0?field:user_id=" + window.user_id,

<script>
    import IntroPage from "./IntroPage.vue";
    import ShowHideTextTool from "./ShowHideTextTool.vue";

    var dataRefs = {
        landsat1984: "https://tiles.arcgis.com/tiles/RbjlVNAtGGPx1hPV/arcgis/rest/services/b123_19841216/MapServer",
        landsat2018: "https://tiles.arcgis.com/tiles/RbjlVNAtGGPx1hPV/arcgis/rest/services/Landsat_8_Jan_28_2018/MapServer",
        survey1: "https://survey123.arcgis.com/share/3f7f8fc36f5a4b9a8d8c26cd0a24d1b6?field:user_id=" + window.user_id,
        survey2: "https://survey123.arcgis.com/share/1d0dbcb0b7d34897a7ca4c589dfce8f0?field:user_id=" + window.user_id,
        survey3: ""
    }


    export default {
        name: "GlacierMap",
        components: {
            IntroPage, ShowHideTextTool
        },
        props: {},
        data: function () {
            return {
                frame: 1,
                frameReference: null,
                loadingState: "loading",  // "complete", "error"
                glacierList: [],
                activeGlacier: null,
                activeGlacierGeoJSONLayer: null,
                seenInfographic: false,
                glacierBoundingBoxes: null,
                outline1984: null,
                liveGraphicsObject: null,
                outline2018: null,
                hasUserCompletedSurvey2: false,
                showDrawingInstructions: false,
                renderers: {
                    boundsRenderer: {
                        type: "simple",
                        symbol: {
                            type: "simple-marker",
                            style: "x",
                            color: "green",
                            outline: {
                                color: [20, 160, 10, 1],
                                width: 3
                            }
                        }
                    },
                    digitisedGlaciers: {
                        type: "unique-value",
                        field: "ObjectID",
                        defaultSymbol: { type: "simple-fill" },
                        uniqueValueInfos: [{
                            value: 1,
                            symbol: {
                                type: "simple-fill",
                                color: [40, 100, 200, 0.5],
                                style: "solid",
                                outline: {
                                    color: [40, 100, 200, 1],
                                    width: 1.5
                                }
                            }
                        }, {
                            value: 2,
                            symbol: {
                                type: "simple-fill",
                                color: [250, 10, 10, 0.5],
                                style: "solid",
                                outline: {
                                    color: [250, 10, 10, 1],
                                    width: 1.5
                                }
                            }
                        }],
                    }
                }
            }
        },
        methods: {
            loadGlaciers: function () {
                $.ajax({
                    url: "https://services5.arcgis.com/RbjlVNAtGGPx1hPV/ArcGIS/rest/services/GlacierMap30BBox/FeatureServer/0/query?f=geojson&where=1=1&outFields=ID",
                    method: "GET",
                    context: this

                }).done(function(data) {
                    if ("error" in data) {
                        this.loadingState = "error";

                        Sentry.configureScope(function(scope) {
                          scope.setExtra("xhr", data);
                        });
                        Sentry.captureMessage("Error loading glacier bounding boxes - error reported from ArcGIS online")

                        return
                    }

                    this.glacierBoundingBoxes = data;

                    data.features.forEach(function (feature) {
                        this.glacierList.push(feature.properties.ID);
                    }.bind(this));

                    // don't start creating map things until the ESRI library has loaded
                    var waitForEsri = window.setInterval(function () {
                        if (window.esriComponentsLoaded) {
                            clearTimeout(waitForEsri);
                            this.prepareActiveGlacierBoundaries();
                            this.loadingState = "complete";
                            // this.frame = 7;
                        }
                    }.bind(this), 500);

                }).fail(function(xhr) {
                    this.loadingState = "error";

                    Sentry.configureScope(function(scope) {
                      scope.setExtra("xhr", xhr);
                    });
                    Sentry.captureMessage("Error loading glacier bounding boxes")
                });
            },
            prepareActiveGlacierBoundaries: function () {
                let boundsGeoJSON = null;
                this.activeGlacier = (this.glacierList.splice(Math.floor(Math.random() * this.glacierList.length), 1)[0]);

                this.glacierBoundingBoxes.features.forEach(function (box) {
                    if (box.properties.ID === this.activeGlacier) {
                        boundsGeoJSON = box;
                    }
                }.bind(this));

                if (!boundsGeoJSON) {
                    Sentry.captureMessage("Missing boundsGeoJSON")
                    return
                }

                const geoJSONBlob = new Blob([JSON.stringify(boundsGeoJSON)], {type: "application/json"});
                const url = URL.createObjectURL(geoJSONBlob);
                this.activeGlacierGeoJSONLayer = new GeoJSONLayer({url: url, renderer: this.renderers.boundsRenderer});
            },
            showSurvey1: function () {
                // Show the first survey in frame 2
                let iframe = document.createElement("iframe");
                iframe.src = dataRefs.survey1;
                this.frameReference.appendChild(iframe);

                var detectCompletion = window.setInterval(function () {
                    $.ajax({
                        url: "/monitor_survey_completion/1/",
                        method: "GET",
                        context: this,
                        data: {
                            user_id: window.user_id
                        }

                    }).done(function(data) {
                        if (data.survey1 === "complete") {
                            clearTimeout(detectCompletion);

                            this.frameReference.innerHTML = "";
                            this.frame = 2.5
                        }

                    }).fail(function(xhr) {
                        clearTimeout(detectCompletion);

                        Sentry.configureScope(function(scope) {
                          scope.setExtra("xhr", xhr);
                          scope.setExtra("xhr.statusCode", xhr.statusCode());
                          scope.setExtra("xhr.statusText", xhr.statusText);
                          scope.setExtra("xhr.responseHeader", xhr.getResponseHeader());
                        });
                        Sentry.captureMessage("Error checking survey 1 completed")
                    });
                }.bind(this), 5000);
            },
            createDigitisingMap: function (year) {
                const mapContainer = year === 1984 ? "map1" : "map2"
                const landsatLayer = year === 1984 ? new TileLayer(dataRefs.landsat1984) : new TileLayer(dataRefs.landsat2018);
                const activeGlacierBounds = this.activeGlacierGeoJSONLayer;  // annoyingly this is necessary as using bind inside the following promise mangles some esri stuff
                const graphicsLayer = new GraphicsLayer();

                // reset outlines when creating map (either first time, or during another pass)
                if (year === 1984) {
                    this.outline1984 = null;
                } else if (year === 2018) {
                    this.outline2018 = null;
                }

                const map = new ArcGISMap({
                    basemap: "topo-vector"
                });

                const view = new MapView({
                    container: mapContainer,
                    map: map,
                    center: [-76.5, -8.2],
                    zoom: 8,
                    constraints: {
                        minZoom: 9,
                        maxZoom: 15,
                        rotationEnabled: false
                    }
                });

                map.layers.add(landsatLayer);

                activeGlacierBounds.when(function () {
                    view.goTo(activeGlacierBounds.fullExtent);
                });
                map.layers.add(activeGlacierBounds);

                map.layers.add(graphicsLayer);

                const sketch = new Sketch({
                    view: view,
                    layer: graphicsLayer,
                    availableCreateTools: ["polygon"],
                    creationMode: "single",
                });
                this.liveGraphicsObject = graphicsLayer;

                view.ui.add(sketch, "top-right");

                // sketch created
                sketch.on("create", function(event) {
                    // removes any existing graphics, only allowing 1 feature to exist at once
                    if (event.state === "start") {
                        graphicsLayer.removeAll();
                    }

                    if (event.state === "complete") {
                        // console.log("sketch created: complete", event)
                        if (year === 1984) {
                            this.outline1984 = event.graphic;
                        } else {
                            this.outline2018 = event.graphic;
                        }
                    }
                }.bind(this));

                // user deletes sketch, clear outline in storage so move-on dialog disappears
                sketch.on("delete", function () {
                      if (year === 1984) {
                          this.outline1984 = null;
                      } else {
                          this.outline2018 = null;
                      }
                }.bind(this));

                // redo should always result in a changed graphic
                sketch.on("redo", function () {
                      if (year === 1984) {
                          this.outline1984 = graphicsLayer.graphics.items[0];
                      } else {
                          this.outline2018 = graphicsLayer.graphics.items[0];
                      }
                }.bind(this));

                // undo could result in no graphic, clear out outline storage as necessary
                sketch.on("undo", function () {
                    if (graphicsLayer.graphics.items.length === 1) {
                        if (year === 1984) {
                            this.outline1984 = graphicsLayer.graphics.items[0];
                        } else {
                            this.outline2018 = graphicsLayer.graphics.items[0];
                        }

                    } else {
                        if (year === 1984) {
                            this.outline1984 = null;
                        } else {
                            this.outline2018 = null;
                        }
                    }
                }.bind(this));

                // update can have no graphic if fired after delete, etc
                sketch.on("update", function () {
                    if (graphicsLayer.graphics.items.length === 1) {
                        if (year === 1984) {
                            this.outline1984 = graphicsLayer.graphics.items[0];
                        } else {
                            this.outline2018 = graphicsLayer.graphics.items[0];
                        }

                    } else {
                        if (year === 1984) {
                            this.outline1984 = null;
                        } else {
                            this.outline2018 = null;
                        }
                    }
                }.bind(this));

            },
            submitBoundaryAndMoveOn: function (year) {
                // Original feature layer was "DigitisedGlaciers" on ArcOnline, now "DigitisedGlaciers_afterJuly2022"
                const features = new FeatureLayer({
                    portalItem: {
                        id: "d36616df09354799bf82ea7329705bac"
                    }
                });

                let newGlacier = year === 1984 ? this.outline1984 : this.outline2018;

                newGlacier["attributes"] = {};
                newGlacier["attributes"]["User_ID"] = window.user_id;
                newGlacier["attributes"]["Glacier_ID"] = this.activeGlacier;
                newGlacier["attributes"]["Glacier_Date"] = year;
                features.applyEdits({addFeatures: [newGlacier]})

                if (year === 1984) {
                    document.getElementById("map1").innerHTML = "";
                    this.frame = 4;
                } else if (year === 2018) {
                    document.getElementById("map2").innerHTML = "";
                    this.frame = 5;
                }
            },
            deleteLiveBoundary: function (year) {
                this.liveGraphicsObject.removeAll();

                if (year === 1984) {
                    this.outline1984 = null;
                } else if (year === 2018) {
                    this.outline2018 = null;
                }
            },
            createMapResults: function () {
                // Show the combined results map in frame 5
                var mapCombined = new ArcGISMap({
                    basemap: "topo-vector",
                    layers: []
                });

                var view = new MapView({
                    container: "map3",
                    map: mapCombined,
                    center: [-76.5, -8.2],
                    zoom: 8,
                    constraints: {
                        minZoom: 9,
                        maxZoom: 16,
                        rotationEnabled: false
                    }
                });

                const digitised1984 = new Graphic({
                    geometry: this.outline1984.geometry
                });
                const digitised2018 = new Graphic({
                    geometry: this.outline2018.geometry
                })

                const digitisedGlaciers = new FeatureLayer({
                    source: [digitised1984, digitised2018],
                    objectIdField: "ObjectID",
                    renderer: this.renderers.digitisedGlaciers
                })

                digitisedGlaciers.queryExtent().then(function (response) {
                    view.goTo(response.extent)
                })

                mapCombined.layers.add(digitisedGlaciers);

            },
            showSurvey2: function () {
                // Show the second survey in frame 7
                let iframe = document.createElement("iframe");
                iframe.src = dataRefs.survey2;
                this.frameReference.appendChild(iframe);

                var detectCompletion = window.setInterval(function () {
                    $.ajax({
                        url: "/monitor_survey_completion/2/",
                        method: "GET",
                        context: this,
                        data: {
                            user_id: window.user_id
                        }

                    }).done(function(data) {
                        if (data.survey2 === "complete") {
                            clearTimeout(detectCompletion);

                            this.hasUserCompletedSurvey2 = true;
                            this.frameReference.innerHTML = "";
                            this.frame = 8;
                        }

                    }).fail(function(xhr) {
                        clearTimeout(detectCompletion);

                        Sentry.configureScope(function(scope) {
                          scope.setExtra("xhr", xhr);
                          scope.setExtra("xhr.statusCode", xhr.statusCode());
                          scope.setExtra("xhr.statusText", xhr.statusText);
                          scope.setExtra("xhr.responseHeader", xhr.getResponseHeader());
                        });
                        Sentry.captureMessage("Error checking survey 2 completed")
                    });
                }.bind(this), 5000);
            },
            digitiseAnotherGlacier: function () {
                this.prepareActiveGlacierBoundaries();
                this.frame = 3;
            },
            redirectToPrizeDraw: function () {
                window.location.href = "https://survey123.arcgis.com/share/577f0fa1b42c4f5eb5018791fbd01c27";
            },
            hideMap3andMoveOn: function () {
                document.getElementById("map3").innerHTML = "";
                this.$nextTick(function () {
                    if (this.seenInfographic) {
                        this.frame = 8;
                    } else {
                        this.seenInfographic = true;
                        this.frame = 6;
                    }
                });

            },
        },
        computed: {
            userCode: function () {
                return window.user_id;
            }
        },
        watch: {
            frame: function () {
                this.$nextTick(function () {
                    this.frameReference = this.$el.getElementsByClassName("frame frame" + this.frame)[0];

                    switch (this.frame) {
                        // case 2:
                        //     this.showSurvey1();
                        //     break;
                        case 3:
                            window.setTimeout(function () {
                                // timeout as map rendering issue when things start too quickly
                                this.createDigitisingMap(1984);
                            }.bind(this), 300)
                            break;
                        case 4:
                            this.createDigitisingMap(2018);
                            break;
                        case 5:
                            this.createMapResults();
                            break;
                        case 6:
                            // Don't show the interstitial infographic if the user has already completed survey 2)
                            if (this.hasUserCompletedSurvey2) {
                                this.frame = 8;
                            }
                            break;
                        // case 7:
                        //     this.showSurvey2();
                        //     break;
                    }
                });
            }
        },
        mounted: function () {
            this.loadGlaciers()
        }
    }
</script>


<style lang="scss">
    .glacier-map-interface {
        width: 100%;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }


    .frame {
        width: 100%;
        height: 100%;
        position: relative;
    }

    #map1, #map2, #map3 {
        width: 100%; height: 100%;
    }

    .map-overlay-content {
        position: absolute;
        top: 70px; right: 15px;
        border: 1px solid #323232;
        background-color: white;

        width: 205px;
        max-height: 80%;
        overflow-y: auto;

        padding: 4px;
        font-size: 0.8rem;

    }

    .content-window {
        position: absolute;
        z-index: 9999;
        top: 0; right: 0; bottom: 0; left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        > div:first-of-type {
            width: 1000px;
            max-width: 95%;
            max-height: 95%;
            overflow-y: auto;
            padding: 15px;
            background-color: white;
            border: 1px solid grey;

            img {
                max-width: 100%;
            }
        }
    }

    .content-window-background {
        background-color: rgba(33, 142, 204, 0.1);
    }

    .split-content {
        display: flex;
        align-items: stretch;
        justify-items: center;

        div:first-of-type {

        }

        div:last-of-type {
            margin-left: 15px;

        }
    }

    .confirm-sketch {
        position: absolute;
        top: 5px; left: 50%;
        transform: translate(-50%);
        z-index:9999;
        width: 390px;
        max-width: 95%;
        text-align: justify;

        border: 1px solid grey;
        background-color: white;
        padding: 5px;
        font-size: 0.8rem;

        p:last-of-type {
          margin: 5px 0 0 0;

            button:first-of-type {
                float: left;
            }
            button:last-of-type {
                float: right;
            }
        }
    }

    .final-image {
        position: absolute;
        z-index: 9990;
        top: 0; right: 0; bottom: 0; left: 0;
        background-size: cover;
    }

    .instructions-modal {
        position: absolute;
        z-index: 9999;
        top: 0; right: 0; bottom: 0; left: 0;
        background: rgba(0, 0, 0, .5);
        display: flex;
        justify-content: center; /* align horizontal */
        align-items: center; /* align vertical */

        > div {
            position: relative;
            padding: 15px;
            background-color: white;
            border: 1px solid grey;
            max-width: 400px;
            max-height: 90%;
            overflow-y: scroll;

            div {
                position: absolute;
                top: 0; right: 10px;
                font-size: 180%;
                cursor: pointer;
            }
        }
    }

</style>