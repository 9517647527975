/**
ShowHideTextTool
*/


<template>
    <div class="show-hide-text">
        <slot name="content" v-if="textVisible"></slot>
        <div class="visibility-message" v-on:click="textVisible = !textVisible">{{ visibilityMessage }}</div>
    </div>
</template>


<script>
    // import store from "../store/_index";


    export default {
        name: "ShowHideTextTool",
        components: {},
        // store,
        props: {},
        data: function () {
            return {
                textVisible: true
            }
        },
        methods: {},
        computed: {
            visibilityMessage: function () {
                if (this.textVisible) {
                    return "Hide this text"
                } else {
                    return "Show help text"
                }
            }
        },
        watch: {},
        mounted: function () {
        }
    }
</script>


<style lang="scss">
    .visibility-message {
        font-size: 90%;
        cursor: pointer;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }
</style>