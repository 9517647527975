var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "intro-content" }, [
    _c("img", {
      attrs: { src: "glacier-header.jpg", alt: "A picture of a glacier" },
    }),
    _vm._v(" "),
    _c("h1", [_vm._v("Welcome to GlacierMap")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n        GlacierMap is a free online tool for mapping glacier change in the Cordillera Blanca mountain range of the Peruvian Andes. We designed this app with two primary\n        purposes: the first, to promote education of glacier change and the knock-on impacts for downstream communities and environments; the second, to collect data on\n        glacier area change in the region through “crowd sourcing” and contribute to understanding of rate of glacial retreat in response to the warming climate.\n    "
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n        To map glacier change in the Cordillera Blanca, we call on you, the general public, to help! Our citizen science glacier inventory will contribute to scientific\n        data collection, while also helping to communicate the impacts of global heating and glacier retreat for downstream resources such as water, food, and energy\n        production in mountain regions around the world that are reliant on glaciers as a key freshwater source.\n    "
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n        We expect the activity to take you around 15 minutes. There is a step-by-step guide showing you how to digitally map a glacier from satellite (Landsat) imagery\n        with an opportunity for you to practice. You will then be able to map a glacier in the Cordillera Blanca for both 1984 and 2018 and visualise the change over\n        this 34-year period. You will have an opportunity to map more glaciers after this if you want to. Finally, you will be provided with information on some of the\n        impacts of glacier retreat for water, food, and energy security in Peru, so you can learn more about why glacier retreat in the Andes, and other mountain ranges,\n        matters for both local people and the environment. Note that your contributions are anonymous.\n    "
      ),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n        The glaciers you map in GlacierMap will instantly contribute to our collection of publicly-mapped glaciers in Peru. This will produce a citizen science glacier\n        inventory which can be used for scientific research, so thank you for taking the time to do this!\n    "
      ),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.loadingState === "error"
      ? _c("div", { staticClass: "continue" }, [
          _vm._v(
            "\n        An error occurred loading the GlacierMap data - the administrator has been notified.\n        "
          ),
          _c("br"),
          _vm._v("\n        You can try again by reloading the page.\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadingState === "loading" && _vm.allowContinue
      ? _c("div", { staticClass: "continue" }, [
          _vm._v("\n        Loading data, please wait...\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadingState === "complete"
      ? _c("div", { staticClass: "continue" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.checkConsent.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n            Continue to GlacierMap\n        ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n        For more information on our research in Peru, please visit our project website at\n        "
      ),
      _c(
        "a",
        {
          attrs: { href: "https://sigmaperu.wordpress.com/", target: "_blank" },
        },
        [_vm._v("https://sigmaperu.wordpress.com/")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }