var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "show-hide-text" },
    [
      _vm.textVisible ? _vm._t("content") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "visibility-message",
          on: {
            click: function ($event) {
              _vm.textVisible = !_vm.textVisible
            },
          },
        },
        [_vm._v(_vm._s(_vm.visibilityMessage))]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }