var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "glacier-map-interface" }, [
    _vm.frame === 1
      ? _c("div", { staticClass: "frame frame1" }, [
          _c(
            "div",
            { staticClass: "content-window content-window-background" },
            [
              _c("intro-page", {
                attrs: { "loading-state": _vm.loadingState },
                on: {
                  "user-confirmed": function ($event) {
                    _vm.frame = 2.5
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.frame === 2.5
      ? _c("div", { staticClass: "frame frame2b" }, [
          _c(
            "div",
            { staticClass: "content-window content-window-background" },
            [
              _c("div", [
                _c("div", { staticClass: "split-content" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-lg",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.frame = 3
                          },
                        },
                      },
                      [_vm._v("Click/tap here to move on")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: { src: "Peru_map.jpg", alt: "Map of Peru" },
                }),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.frame === 3
      ? _c("div", { staticClass: "frame frame3" }, [
          _c("div", { attrs: { id: "map1" } }),
          _vm._v(" "),
          _vm.showDrawingInstructions
            ? _c(
                "div",
                {
                  staticClass: "instructions-modal",
                  on: {
                    click: function ($event) {
                      _vm.showDrawingInstructions = false
                    },
                  },
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.showDrawingInstructions = false
                          },
                        },
                      },
                      [_vm._v("X")]
                    ),
                    _vm._v(" "),
                    _c("h4", [_vm._v("How to map a glacier")]),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                        If you are not happy with your mapping you can press the “undo” button to retrace your steps or start again. This will allow you to practise if you wish. You will also be given the option to delete the shape and start again after double clicking. To move the satellite image into a better position on the screen click on the four-headed arrow button then drag the page.\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                        Do not worry if you are unsure about your mapping, sometimes snow and debris can make it difficult to interpret where the glacier edge starts and ends, so just do your best!\n                    "
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.outline1984
            ? _c("div", { staticClass: "confirm-sketch" }, [
                _vm._v(
                  "\n              If you're not happy with your shape, you can edit it by clicking/tapping it once to allow moving to a new location,\n              and again to change the individual points that make up the boundary.\n              "
                ),
                _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-sm",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.deleteLiveBoundary(1984)
                        },
                      },
                    },
                    [_vm._v("Delete shape and start again")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitBoundaryAndMoveOn(1984)
                        },
                      },
                    },
                    [_vm._v("Move on to next map")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "map-overlay-content" },
            [
              _c("show-hide-text-tool", [
                _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _c("p", [
                    _vm._v(
                      "\n                            We would now like you to have a go at mapping a Peruvian glacier.\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n                            We are mapping glaciers in the Cordillera Blanca mountain range in the Peruvian Andes,\n                            which contains the largest area of tropical glaciers in the world! The image you can\n                            see was taken back in 1984 by the Landsat satellite and the green cross marks the approximate\n                            center of the glacier.\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      staticStyle: { margin: "0 0 10px 0 !important" },
                      on: {
                        click: function ($event) {
                          _vm.showDrawingInstructions = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            Click here to learn how to map the glacier you've been assigned.\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.frame === 4
      ? _c("div", { staticClass: "frame frame4" }, [
          _c("div", { attrs: { id: "map2" } }),
          _vm._v(" "),
          _vm.outline2018
            ? _c("div", { staticClass: "confirm-sketch" }, [
                _vm._v(
                  "\n              If you're not happy with your shape, you can edit it by clicking/tapping it once to allow moving to a new location,\n              and again to change the individual points that make up the boundary.\n              "
                ),
                _c("p", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-sm",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.deleteLiveBoundary(2018)
                        },
                      },
                    },
                    [_vm._v("Delete shape and start again")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-sm",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitBoundaryAndMoveOn(2018)
                        },
                      },
                    },
                    [_vm._v("Move on to next map")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "map-overlay-content" },
            [
              _c("show-hide-text-tool", [
                _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _c("p", [
                    _vm._v(
                      "Great work! For this second part, we now ask for you to try mapping the same glacier again, but from this image taken more recently, in 2018."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("img", {
                      attrs: { src: "draw-icon.png", alt: "draw icon" },
                    }),
                    _vm._v(
                      "\n                            Like before, please just do your best at drawing a line around what you consider to be the edge of the glacier.\n                        "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.frame === 5
      ? _c("div", { staticClass: "frame frame5" }, [
          _c("div", { attrs: { id: "map3" } }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "map-overlay-content",
              staticStyle: { top: "15px" },
            },
            [
              _c("p", [
                _vm._v(
                  "Thank you for contributing those two glacier outlines to our project!"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "We can show you here the two outlines that you mapped. The blue shape is the glacier you mapped from 1984, and the red shape is the glacier you mapped from 2018. Any difference you see between the two outlines indicates change in the glacier extent over those 34 years."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Please continue on to finish GlacierMap to learn more about the impacts of glacier retreat in Peru."
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                { staticStyle: { margin: "0", "text-align": "center" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.hideMap3andMoveOn.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Click/tap here"), _c("br"), _vm._v("to move on")]
                  ),
                ]
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.frame === 6
      ? _c("div", { staticClass: "frame frame6" }, [
          _c(
            "div",
            { staticClass: "content-window content-window-background" },
            [
              _c("div", [
                _c("div", { staticClass: "split-content" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-lg",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.frame = 8
                          },
                        },
                      },
                      [_vm._v("Click/tap here to move on")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: "GlacierMap%20impacts%20infographic%20final.jpg",
                    alt: "An image describing glacier retreat",
                  },
                }),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.frame === 8
      ? _c("div", { staticClass: "frame frame8" }, [
          _c(
            "div",
            { staticClass: "content-window content-window-background" },
            [
              _c("div", [
                _vm.glacierList.length > 0
                  ? _c("div", {}, [
                      _c("h2", [
                        _vm._v(
                          "Would you like to do the glacier mapping exercise again for a different glacier before you finish?"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                            If so, please feel free to have another go here! Any mapping you do will contribute to the citizen science glacier inventory for the Cordillera Blanca mountain range in Peru.\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("If not, please carry on to our final page!"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.glacierList.length === 0
                  ? _c("div", [
                      _c("h2", [
                        _vm._v(
                          "You've mapped all of the glaciers! Thank you for your contribution."
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.glacierList.length > 0
                  ? _c("div", { staticStyle: { display: "inline-block" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-lg",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.digitiseAnotherGlacier.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Digitise another glacier\n                        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticStyle: { display: "inline-block" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-lg",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.frame = 9
                        },
                      },
                    },
                    [_vm._v("Finish")]
                  ),
                ]),
                _vm._v(" "),
                _vm.glacierList.length === 0
                  ? _c("div", {}, [
                      _vm._v("Digitised all the glaciers message"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("br"),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.frame === 9
      ? _c("div", { staticClass: "frame frame9" }, [
          _c("div", {
            staticClass: "final-image",
            staticStyle: {
              background:
                "url('laguna_69_glacier_peru.jpg') no-repeat center center fixed",
            },
          }),
          _vm._v(" "),
          _vm._m(3),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          "\n                                Here is a map of the world with Peru highlighted.\n                                The Peruvian Andean mountains (labelled as ANDES MNTS on the smaller map of Peru) reach higher than 6,000 m above sea level, and are home to many of the world’s tropical glaciers.\n                                Glaciers are an important source of water for the environment and for people.\n                            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("img", { attrs: { src: "draw-icon.png", alt: "draw icon" } }),
      _vm._v(
        "\n                        Try to draw a line around what you consider to be the edge of the glacier by selecting the shape icon (the text “draw a polygon” will appear when you hover the mouse over this) and clicking at multiple points around the edge of the glacier. Double click to “connect the dots” and complete your shape.\n                    "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          "\n                                For many of the glaciers in the Peruvian Andes, there will be a difference in the glacier outlines over the last 34 years. This represents glacial retreat, where glaciers become smaller and smaller in area, mainly due to a warming climate.\n                            "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                                Please use this diagram to find out more information about glacial retreat in Peru and the impacts of the loss of glaciers for people living there and the environment.\n                            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-window" }, [
      _c("div", [
        _c("h2", [_vm._v("Thank you for contributing to GlacierMap!")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("A huge "),
          _c("span", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("THANK YOU"),
          ]),
          _vm._v(
            " from the GlacierMap team for your interest in our research and for taking the time to contribute to our citizen science glacier inventory!"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Please feel free to share GlacierMap with your friends, colleagues, and students."
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n                        To learn more about our research exploring glacier retreat and its impact on water security in the Peruvian Andes, please visit our project website and Twitter page via the links below. You can also find our contact details on the SIGMA: Peru website if you have any questions about GlacierMap or would like to give us feedback.\n                        "
          ),
          _c("ul", [
            _c("li", [
              _vm._v("SIGMA: Peru website: "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://sigmaperu.wordpress.com/",
                    target: "_blank",
                  },
                },
                [_vm._v("https://sigmaperu.wordpress.com/")]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Twitter: "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://twitter.com/sigmaperu",
                    target: "_blank",
                  },
                },
                [_vm._v("@sigmaperu")]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n                        If you would like to learn more about how GlacierMap can be used to support geographical learning, please take a look at our paper in the Geographical Association’s Geography journal:\n                        "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://doi.org/10.1080/00167487.2021.1970932",
                target: "_blank",
              },
            },
            [_vm._v("https://doi.org/10.1080/00167487.2021.1970932")]
          ),
          _vm._v(".\n                    "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }